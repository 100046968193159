<template>
  <div class="row m-0 justify-content-center">
    <div class="col-11 col-sm-10 col-md-9 col-lg-8 col-xl-7 p-0 my-4">
      <div class="row justify-content-center h-100">
        <div class="col-md mb-3">
          <a :href="'tel:' + headOfficePhone.call" target="_blank">
            <Card icon="icon" heading="Call Our Head Office Now">
              <IconPhone color="gold" size="size28" />
            </Card>
          </a>
        </div>
        <div class="col-md mb-3">
          <router-link to="/waystodonate">
            <Card icon="icon" heading="Ways to Donate">
              <IconWays color="gold" size="size28" />
            </Card>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Card: defineAsyncComponent(() => import('@/views/Card.vue')),
    IconPhone: defineAsyncComponent(() => import('@/components/icons/IconPhone.vue')),
    IconWays: defineAsyncComponent(() => import('@/components/icons/IconWays.vue'))
  },
  name: 'QuickContactLinks',
  computed: {
    ...mapGetters([
      'headOffice'
    ]),
    headOfficePhone () {
      const obj = {
        call: '+441254698771',
        display: '+44 (0) 1254 698771'
      }
      if (this.isUK) {
        return obj
      } else {
        if (this.headOffice[0].telephone) {
          var callable = this.headOffice[0].telephone
          callable = callable.replace(/[^\d]/g, '')
          callable = callable.slice(0, 2) + callable.slice(3)
          obj.call = callable
          obj.display = this.headOffice[0].telephone
          return obj
        } else {
          return null
        }
      }
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
